import React, { useEffect } from "react"
import styled from 'styled-components'
import { HomeFooter, ArrowIcon, FooterText, BodyText } from '../../styles/index'
import { HeadLogo, HeadText } from '../../styles/index'
import Typewriter from 'typewriter-effect'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import media from "../../styles/media"

import Logo from '../../images/brandmark.svg'
import Arrow from '../../images/48h_arrow.svg'
import Wordmark from '../../images/wordmark.svg'
import MaroonLine from '../../images/maroon-line.png'

import TimelineDesk from './timeline'
import TimelineMob from "./mobileTimeline"

const HeaderCont = styled.div`
  display: flex;
  justify-content: space-between;
  top: 0vh;
  left: 0vw;
  background-color: var(--light-grey);
  padding: var(--gutter-mobile);
  .logo {
    margin: auto 0;
  }
  .text {
    display: block;
    a {
      text-decoration: none;
      color: var(--logo-red);
      .dark {
        color: var(--maroon);
        div {
          span {
            &:nth-child(1) {
              padding-left: 2px;
            }
            background: url(${MaroonLine});
            background-repeat: repeat-x;
            background-position: center left;
          }
        }
      }
    }
  }
  ${media.tablet`
    padding: var(--gutter-desktop);
    .text {
      display: flex;
      .button {
        line-height: 20px;
        display: block;
        outline: none;
        text-decoration: none;
        font-size: 20px;
        font-family: 'gourmet-hearth';
        background: var(--background);
        transform: translateY(var(--y));
        transition: transform .44s ease;
        div {
          display: flex;
          overflow: hidden;
          text-shadow: 0 20px 0 var(--bright-red);
          span {
            display: block;
            padding-left: 2px;
            backface-visibility: hidden;
            font-style: normal;
            transition: transform .44s ease;
            transform: translateY(var(--m));
            &:nth-child(2) {
              transition-delay: 0.05s;
            }
            &:nth-child(3) {
              transition-delay: 0.1s;
            }
            &:nth-child(4) {
              transition-delay: 0.15s;
            }
            &:nth-child(5) {
              transition-delay: 0.2s;
            }
            &:nth-child(6) {
              transition-delay: 0.25s;
            }
            &:nth-child(7) {
              transition-delay: 0.3s;
            }
            &:nth-child(8) {
              transition-delay: 0.35s;
            }
            &:nth-child(9) {
              transition-delay: 0.4s;
            }
            &:nth-child(10) {
              transition-delay: 0.45s;
            }
            &:nth-child(11) {
              transition-delay: 0.5s;
            }
          }
        }
        &:hover {
          span {
              --m: calc(20px * -1);
          }
        }
        &.dark {
          color: var(--maroon);
          margin-right: 50px;
          div {
            text-shadow: 0 20px 0 var(--maroon);
          }
        }
        &.light {
          color: var(--bright-red);
        }
      }

      html {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
      }

      * {
          box-sizing: inherit;
          &:before,
          &:after {
              box-sizing: inherit;
          }
      }
    }
  `}
`

const HomeCont = styled.div`
  background-color: var(--light-grey);
  .heroSection {
    height: 59vh;
    margin-right: 12vw;
    overflow: hidden;
    display: grid;
    grid-template-rows: 1.2fr 0.8fr 0.3fr 0.5fr 0.8fr;
    padding-top: 5vh;
    .Typewriter {
      text-align: right;
      margin-left: 10vw;
      span {
        text-transform: uppercase;
        text-align: right;
        color: var(--bright-red);
        font-size: 28px;
        line-height: 1.5;
      }
      .Typewriter__cursor {
        animation-duration: 0.25s;
      }
    }
  }
  img {
    &.wordmark {
      justify-self: end;
      width: 45vw;
      margin-top: 0px;
    }
  }
  ${media.tablet`
    .heroSection {
      height: 59vh;
      margin-right: 15vw;
      grid-template-rows: 1.7fr 1fr 1fr 1fr 1fr;
      .Typewriter {
        text-align: right;
        margin-left: 2vw;
        span {
          text-transform: uppercase;
          text-align: right;
          color: var(--bright-red);
          font-size: 50px;
          margin-bottom: 0;
        }
      }
    }
    img {
      &.wordmark {
        width: 18vw;
        margin-top: 20px;
      }
    }
  `}
  ${media.laptopL`
    .heroSection {
      height: 61vh;
    }
  `}
  ${media.laptopXL`
    .heroSection {
      height: 64vh;
    }
  `}
  ${media.desktop`
    .heroSection {
      height: 67vh;
      .Typewriter {
        span {
          font-size: 70px;
        }
      }
    }
  `}
`

const Hero = () => {

  useEffect(() => {
    document.querySelectorAll('.button').forEach(button => button.innerHTML = '<div><span>' + button.textContent.trim().split('').join('</span><span>') + '</span></div>');
  })

  return (
    <>
      <HeaderCont>
        <AniLink cover direction="down" to="/" bg="#66141D" className="logo"><HeadLogo src={Logo} alt="logo" /></AniLink>
        <div className="text">
          <AniLink cover direction="down" to="/" bg="#66141D">
            <HeadText className="dark button">
            Home
            </HeadText>
          </AniLink>
          <AniLink cover direction="up" to="/contact" bg="#66141D">
            <HeadText className="light button">
                Contact &nbsp;Us
            </HeadText>
          </AniLink>
        </div>
      </HeaderCont>
      <HomeCont>
        <div className="heroSection">
          <div>
          <div className="desktop">
          <Typewriter
            options={{ delay: 150, deleteSpeed: 200, blinkSpeed: 200}}
            onInit={(typewriter) => {
              typewriter.typeString('The first 48 hours are critical')
                .pauseFor(1000)
                .deleteAll()
                .start();
                typewriter.typeString('Online crisis, real world solution')
                .pauseFor(1000)
                .deleteAll()
                .start();
                typewriter.typeString('Control the narrative')
                .pauseFor(1000)
                .deleteAll()
                .start();
                typewriter.typeString('Keep calm and call us')
                .pauseFor(1000)
                .deleteAll()
                .start();
                typewriter.typeString('Crisis averted')
                .start();
            }}
          />
          </div>
          </div>
          <TimelineMob className="mobile" />
          <TimelineDesk className="desktop" />
          <img className="wordmark" src={Wordmark} alt="wordmark" />
          <BodyText>social media<br/>crisis consultancy</BodyText>
        </div>

        <HomeFooter>
          <AniLink cover direction="up" to="/contact" bg="#66141D">
            <ArrowIcon className="arrow" src={Arrow} />
          </AniLink>
          <div className="contact">
            <FooterText href="tel:+27823781691">082 378 1691</FooterText> <br/>
            <FooterText href="mailto:mike@48h.co.za">mike@48h.co.za</FooterText>
          </div>
        </HomeFooter>
      </HomeCont>
    </>
  )
}

export default Hero
