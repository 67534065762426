import React from "react"
import styled from 'styled-components'
import { FooterText, ContactText } from '../../styles/index'
import { HeadLogo, HeadText } from '../../styles/index'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import media from "../../styles/media"

import Logo from '../../images/brandmark.svg'
import ContactUs from "./form"
import WhiteLine from '../../images/white-line.png'

const HeaderCont = styled.div`
  display: flex;
  justify-content: space-between;
  top: 0vh;
  left: 0vw;
  background-color: var(--maroon);
  padding: var(--gutter-mobile);
  .logo {
    margin: auto 0;
  }
  .text {
    display: block;
    a {
      text-decoration: none;
      color: var(--white);
      .dark {
        color: var(--logo-red);
      }
      .light {
        div {
          span {
            &:nth-child(1) {
              padding-left: 2px;
            }
            background: url(${WhiteLine});
            background-repeat: repeat-x;
            background-position: center left;
          }
        }
      }
    }
  }
  ${media.tablet`
    padding: var(--gutter-desktop);
    .text {
      display: flex;
      .button {
        line-height: 20px;
        display: block;
        outline: none;
        text-decoration: none;
        font-size: 20px;
        font-family: 'gourmet-hearth';
        background: var(--background);
        transform: translateY(var(--y));
        transition: transform .44s ease;
        div {
          display: flex;
          overflow: hidden;
          text-shadow: 0 20px 0 var(--white);
          span {
            display: block;
            padding-left: 2px;
            backface-visibility: hidden;
            font-style: normal;
            transition: transform .44s ease;
            transform: translateY(var(--m));
            &:nth-child(2) {
              transition-delay: 0.05s;
            }
            &:nth-child(3) {
              transition-delay: 0.1s;
            }
            &:nth-child(4) {
              transition-delay: 0.15s;
            }
            &:nth-child(5) {
              transition-delay: 0.2s;
            }
            &:nth-child(6) {
              transition-delay: 0.25s;
            }
            &:nth-child(7) {
              transition-delay: 0.3s;
            }
            &:nth-child(8) {
              transition-delay: 0.35s;
            }
            &:nth-child(9) {
              transition-delay: 0.4s;
            }
            &:nth-child(10) {
              transition-delay: 0.45s;
            }
            &:nth-child(11) {
              transition-delay: 0.5s;
            }
          }
        }
        &:hover {
          span {
              --m: calc(20px * -1);
          }
        }
        &.dark {
          color: var(--logo-red);
          margin-right: 50px;
          div {
            text-shadow: 0 20px 0 var(--logo-red);
          }
        }
        &.light {
          color: var(--white);
        }
      }

      html {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
      }

      * {
          box-sizing: inherit;
          &:before,
          &:after {
              box-sizing: inherit;
          }
      }
    }
  `}
`

const ContactCont = styled.div`
  background-color: var(--bright-red);
  height: 82vh;
  padding-bottom: 3vh;
  .contact {
    margin: 5vh 10vw 0;
    a {
      line-height: 1.8;
      transition: all 0.3s ease-out; 
      &:hover {
        color: var(--maroon);
      }
    }
  }
  ${media.tablet`
    height: 82.5vh;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .contact {
      margin: 58vh 5vw 0;
      img {
        display: none;
      }
    }
  `}
`

const Contact = () => {
  return (
    <>
      <HeaderCont>
        <AniLink cover direction="down" to="/" bg="#66141D" className="logo"><HeadLogo src={Logo} /></AniLink>
        <div className="text">
          <AniLink cover direction="down" to="/" bg="#66141D">
            <HeadText className="dark button">
              Home
            </HeadText>
          </AniLink>
          <AniLink cover direction="up" to="/contact" bg="#66141D">
            <HeadText className="light button">
                Contact &nbsp;Us
            </HeadText>
          </AniLink>
        </div>
      </HeaderCont>
      <ContactCont>
        <div></div>
        <div>
          <ContactText>Contact us</ContactText>
          <ContactUs />
        </div>
        <div className="contact">
          <FooterText href="tel:+27823781691">082 378 1691</FooterText> <br/>
          <FooterText href="mailto:mike@48h.co.za">mike@48h.co.za</FooterText>
        </div>
      </ContactCont>
    </>
  )
}

export default Contact
