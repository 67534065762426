import React from 'react'
import styled, { keyframes } from 'styled-components'
import media from '../../styles/media'

const showTimeline = keyframes`
  from {
    fill: var(--maroon);
  }
  to {
    fill: #DF2127;
  }
`

const AnimationContainer = styled.div`
  width : 88vw; 
  ${media.tablet`
    display: none;
  `}
`

const TimelineSvg = styled.svg`
  width: 88vw;
  g {
    width: 88vw;
  }
  .st0{
    fill: #DF2127;
    animation: ${showTimeline} 2.5s forwards infinite;
    &.two {
      animation-delay: 0.1s;
    }
    &.three {
      animation-delay: 0.2s;
    }
    &.four {
      animation-delay: 0.3s;
    }
    &.five {
      animation-delay: 0.4s;
    }
    &.six {
      animation-delay: 0.5s;
    }
    &.seven {
      animation-delay: 0.6s;
    }
    &.eight {
      animation-delay: 0.7s;
    }
    &.nine {
      animation-delay: 0.8s;
    }
    &.ten {
      animation-delay: 0.9s;
    }
    &.eleven {
      animation-delay: 1s;
    }
    &.twelve {
      animation-delay: 1.1s;
    }
    &.thirteen {
      animation-delay: 1.2s;
    }
    &.fourteen {
      animation-delay: 1.3s;
    }
    &.fifteen {
      animation-delay: 1.4s;
    }
    &.sixteen {
      animation-delay: 1.5s;
    }
    &.seventeen {
      animation-delay: 1.6s;
    }
    &.eighteen {
      animation-delay: 1.7s;
    }
    &.nineteen {
      animation-delay: 1.8s;
    }
    &.twenty {
      animation-delay: 1.9s;
    }
    &.twentyone {
      animation-delay: 2s;
    }
    &.twentytwo {
      animation-delay: 2.1s;
    }
    &.twentythree {
      animation-delay: 2.2s;
    }
    &.twentyfour {
      animation-delay: 2.3s;
    }
    &.twentyfive {
      animation-delay: 2.4s;
    }
    &.twentysix {
      animation-delay: 2.5s;
    }
    &.twentyseven {
      animation-delay: 2.6s;
    }
    &.twentyeight {
      animation-delay: 2.7s;
    }
    &.twentynine {
      animation-delay: 2.8s;
    }
  }
`

const TimelineMob = () => (
  <AnimationContainer>
    <TimelineSvg viewBox="0 0 920.8 72.3" style={{enableBackground: 'new 0 0 920.8 72.3'}} className="mobile">
      <g>
        <path class="st0 twentyone" d="M663.9,46.4c0,0.3,0,0.6,0,1v11.5c0,9.1-3.9,13.5-11.4,13.5c-7.6,0-11.4-4.5-11.4-13.5V47.2
          c-0.1-0.8-0.1-1.7,0-2.5c0.1-1.5,0.3-2.9,0.9-4.3c0.6-1.6,1.5-2.9,2.8-4c1.5-1.4,3.2-2.6,4.8-3.8c0.1-0.1,0.2-0.2,0.4-0.3
          c0.9-0.7,1.2-1.6,1.2-2.7c0-0.7,0-1.4-0.4-2c-0.3-0.5-0.7-0.8-1.2-1.2c-1.3-1-2.6-2-3.9-3.1c0,0-0.1-0.1-0.1-0.1
          c-0.8-0.6-1.5-1.3-2.1-2.1c0,0,0,0,0,0c-1.1-1.5-1.8-3.5-2.2-5.9c0,0,0-0.1,0-0.1c-0.2-1.1-0.2-2.3-0.2-3.6V0h22.9
          c0,0,0.2,12.6,0.2,13.8c-0.1,2.1-0.5,4.1-1.4,5.9c-0.6,1.1-1.4,2-2.3,2.9c-1.5,1.3-3.1,2.5-4.7,3.7c-0.1,0.1-0.2,0.2-0.3,0.2
          c-0.8,0.6-1.3,1.5-1.2,2.6c0,0.5,0,0.9,0.1,1.4c0.1,0.7,0.6,1.3,1.2,1.7c1,0.8,2,1.5,2.9,2.2c0.9,0.8,1.8,1.5,2.7,2.4
          c1.4,1.3,2.2,3,2.6,4.8C663.8,43.2,664,44.8,663.9,46.4"/>
        <path class="st0 twentyfour" d="M760.8,46.4c0,0.3,0,0.6,0,1v11.5c0,9.1-3.9,13.5-11.4,13.5c-7.6,0-11.4-4.5-11.4-13.5V47.2
          c-0.1-0.8-0.1-1.7,0-2.5c0.1-1.5,0.3-2.9,0.9-4.3c0.6-1.6,1.5-2.9,2.8-4c1.5-1.4,3.2-2.6,4.8-3.8c0.1-0.1,0.2-0.2,0.4-0.3
          c0.9-0.7,1.2-1.6,1.2-2.7c0-0.7,0-1.4-0.4-2c-0.3-0.5-0.7-0.8-1.2-1.2c-1.3-1-2.6-2-3.9-3.1c0,0-0.1-0.1-0.1-0.1
          c-0.8-0.6-1.5-1.3-2.1-2.1c0,0,0,0,0,0c-1.1-1.5-1.8-3.5-2.2-5.9c0,0,0-0.1,0-0.1c-0.2-1.1-0.2-2.3-0.2-3.6V0h22.9
          c0,0,0.2,12.6,0.2,13.8c-0.1,2.1-0.5,4.1-1.4,5.9c-0.6,1.1-1.4,2-2.3,2.9c-1.5,1.3-3.1,2.5-4.7,3.7c-0.1,0.1-0.2,0.2-0.3,0.2
          c-0.8,0.6-1.3,1.5-1.2,2.6c0,0.5,0,0.9,0.1,1.4c0.1,0.7,0.6,1.3,1.2,1.7c1,0.8,2,1.5,2.9,2.2c0.9,0.8,1.8,1.5,2.7,2.4
          c1.4,1.3,2.2,3,2.6,4.8C760.7,43.2,760.8,44.8,760.8,46.4"/>
        <path class="st0 twentynine" d="M920.8,46.4c0,0.3,0,0.6,0,1v11.5c0,9.1-3.9,13.5-11.4,13.5c-7.6,0-11.4-4.5-11.4-13.5V47.2
          c-0.1-0.8-0.1-1.7,0-2.5c0.1-1.5,0.3-2.9,0.9-4.3c0.6-1.6,1.5-2.9,2.8-4c1.5-1.4,3.2-2.6,4.8-3.8c0.1-0.1,0.2-0.2,0.4-0.3
          c0.9-0.7,1.2-1.6,1.2-2.7c0-0.7,0-1.4-0.4-2c-0.3-0.5-0.7-0.8-1.2-1.2c-1.3-1-2.6-2-3.9-3.1c0,0-0.1-0.1-0.1-0.1
          c-0.8-0.6-1.5-1.3-2.1-2.1c0,0,0,0,0,0c-1.1-1.5-1.8-3.5-2.2-5.9c0,0,0-0.1,0-0.1c-0.2-1.1-0.2-2.3-0.2-3.6V0h22.9
          c0,0,0.2,12.6,0.2,13.8c-0.1,2.1-0.5,4.1-1.4,5.9c-0.6,1.1-1.4,2-2.3,2.9c-1.5,1.3-3.1,2.5-4.7,3.7c-0.1,0.1-0.2,0.2-0.3,0.2
          c-0.8,0.6-1.3,1.5-1.2,2.6c0,0.5,0,0.9,0.1,1.4c0.1,0.7,0.6,1.3,1.2,1.7c1,0.8,2,1.5,2.9,2.2c0.9,0.8,1.8,1.5,2.7,2.4
          c1.4,1.3,2.2,3,2.6,4.8C920.7,43.2,920.9,44.8,920.8,46.4"/>
        <path class="st0 fifteen" d="M460.2,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C471.7,67.7,467.8,72.2,460.2,72.2"/>
        <path class="st0 fourteen" d="M428.1,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C439.6,67.7,435.7,72.2,428.1,72.2"/>
        <path class="st0 seventeen" d="M524.3,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C535.8,67.7,531.9,72.2,524.3,72.2"/>
        <path class="st0 eighteen" d="M556.8,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C568.3,67.7,564.4,72.2,556.8,72.2"/>
        <path class="st0 nineteen" d="M588.9,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C600.4,67.7,596.5,72.2,588.9,72.2"/>
        <path class="st0 twentythree" d="M717.8,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C729.2,67.7,725.4,72.2,717.8,72.2"/>
        <path class="st0 twentytwo" d="M684.8,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8s11.5,4.5,11.5,13.8v11.7
          C696.3,67.7,692.4,72.2,684.8,72.2"/>
        <path class="st0 twenty" d="M620.5,72.3c-7.6,0-11.5-4.5-11.5-13.8V46.8c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C632,67.8,628.1,72.3,620.5,72.3"/>
        <path class="st0 twentyfive" d="M781.1,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C792.6,67.7,788.7,72.2,781.1,72.2"/>
        <path class="st0 twentysix" d="M812.8,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C824.2,67.7,820.4,72.2,812.8,72.2"/>
        <path class="st0 twentyseven" d="M844.5,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C856,67.7,852.1,72.2,844.5,72.2"/>
        <path class="st0 twentyeight" d="M877.6,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C889.1,67.7,885.2,72.2,877.6,72.2"/>
        <path class="st0 sixteen" d="M504.3,46.4c0,0.3,0,0.6,0,1v11.5c0,9.1-3.9,13.5-11.4,13.5c-7.6,0-11.4-4.5-11.4-13.5V47.2
          c-0.1-0.8-0.1-1.7,0-2.5c0.1-1.5,0.3-2.9,0.9-4.3c0.6-1.6,1.5-2.9,2.8-4c1.5-1.4,3.2-2.6,4.8-3.8c0.1-0.1,0.2-0.2,0.4-0.3
          c0.9-0.7,1.2-1.6,1.2-2.7c0-0.7,0-1.4-0.4-2c-0.3-0.5-0.7-0.8-1.2-1.2c-1.3-1-2.6-2-3.9-3.1c0,0-0.1-0.1-0.1-0.1
          c-0.8-0.6-1.5-1.3-2.1-2.1c0,0,0,0,0,0c-1.1-1.5-1.8-3.5-2.2-5.9c0,0,0-0.1,0-0.1c-0.2-1.1-0.2-2.3-0.2-3.6V0h22.9
          c0,0,0.2,12.6,0.2,13.8c-0.1,2.1-0.5,4.1-1.4,5.9c-0.6,1.1-1.4,2-2.3,2.9c-1.5,1.3-3.1,2.5-4.7,3.7c-0.1,0.1-0.2,0.2-0.3,0.2
          c-0.8,0.6-1.3,1.5-1.2,2.6c0,0.5,0,0.9,0.1,1.4c0.1,0.7,0.6,1.3,1.2,1.7c1,0.8,2,1.5,2.9,2.2c0.9,0.8,1.8,1.5,2.7,2.4
          c1.4,1.3,2.2,3,2.6,4.8C504.3,43.2,504.4,44.8,504.3,46.4"/>
        <path class="st0 one" d="M11.5,72.2C3.9,72.2,0,67.7,0,58.4V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C22.9,67.7,19.1,72.2,11.5,72.2"/>
        <path class="st0 two" d="M43.1,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C54.6,67.7,50.7,72.2,43.1,72.2"/>
        <path class="st0 three" d="M74.8,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C86.3,67.7,82.4,72.2,74.8,72.2"/>
        <path class="st0 six" d="M169.5,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C181,67.7,177.1,72.2,169.5,72.2"/>
        <path class="st0 eight" d="M234.4,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C245.9,67.7,242,72.2,234.4,72.2"/>
        <path class="st0 seven" d="M212.9,46.4c0,0.3,0,0.6,0,1v11.5c0,9.1-3.9,13.5-11.4,13.5c-7.6,0-11.4-4.5-11.4-13.5V47.2
          c-0.1-0.8-0.1-1.7,0-2.5c0.1-1.5,0.3-2.9,0.9-4.3c0.6-1.6,1.5-2.9,2.8-4c1.5-1.4,3.2-2.6,4.8-3.8c0.1-0.1,0.2-0.2,0.4-0.3
          c0.9-0.7,1.2-1.6,1.2-2.7c0-0.7,0-1.4-0.4-2c-0.3-0.5-0.7-0.8-1.2-1.2c-1.3-1-2.6-2-3.9-3.1c0,0-0.1-0.1-0.1-0.1
          c-0.8-0.6-1.5-1.3-2.1-2.1c0,0,0,0,0,0c-1.1-1.5-1.8-3.5-2.2-5.9c0,0,0-0.1,0-0.1c-0.2-1.1-0.2-2.3-0.2-3.6V0h22.9
          c0,0,0.2,12.6,0.2,13.8c-0.1,2.1-0.5,4.1-1.4,5.9c-0.6,1.1-1.4,2-2.3,2.9c-1.5,1.3-3.1,2.5-4.7,3.7c-0.1,0.1-0.2,0.2-0.3,0.2
          c-0.8,0.6-1.3,1.5-1.2,2.6c0,0.5,0,0.9,0.1,1.4c0.1,0.7,0.6,1.3,1.2,1.7c1,0.8,2,1.5,2.9,2.2c0.9,0.8,1.8,1.5,2.7,2.4
          c1.4,1.3,2.2,3,2.6,4.8C212.8,43.2,212.9,44.8,212.9,46.4"/>
        <path class="st0 thirteen" d="M394.2,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C405.6,67.7,401.7,72.2,394.2,72.2"/>
        <path class="st0 eleven" d="M330.8,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C342.3,67.7,338.4,72.2,330.8,72.2"/>
        <path class="st0 twelve" d="M361.3,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C372.8,67.7,368.9,72.2,361.3,72.2"/>
        <path class="st0 ten" d="M298.7,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C310.2,67.7,306.3,72.2,298.7,72.2"/>
        <path class="st0 nine" d="M267.5,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C279,67.7,275.1,72.2,267.5,72.2"/>
        <path class="st0 five" d="M137.9,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C149.3,67.7,145.4,72.2,137.9,72.2"/>
        <path class="st0 four" d="M106.2,72.2c-7.6,0-11.5-4.5-11.5-13.8V46.7c0-9.3,3.9-13.8,11.5-13.8c7.6,0,11.5,4.5,11.5,13.8v11.7
          C117.7,67.7,113.8,72.2,106.2,72.2"/>
      </g>
    </TimelineSvg>
  </AnimationContainer>
)

export default TimelineMob
