import React from "react"
import styled from 'styled-components'
import Hero from "./hero"
import {Helmet} from 'react-helmet'

import SocialImg from '../../images/48h_social_sharing.png'

const PageCont = styled.div`
  width: 100vw;
  min-height: 100vh;
`

const HomePage = () => {

  return (
    <PageCont>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="48H"/>
        <meta property="og:description" content="Social media crisis consultancy."/>
        <meta property="og:image" content={SocialImg}/>
      </Helmet>
      <Hero />
    </PageCont>
  )
}

export default HomePage
