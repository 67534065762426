import styled, {keyframes} from 'styled-components'
import media from './media'

export const HeadLogo = styled.img`
  height: 35px;
  margin: auto 0;
  ${media.tablet`
    height: 50px;
  `}
`

export const HeadText = styled.p`
  font-family: 'gourmet-hearth';
  font-size: 14px;
  /* letter-spacing: 1.5px; */
  text-align: right;
  padding-left: 2px;
  ${media.tablet`
    font-size: 20px;
  `}
`

export const BodyText = styled.p`
  color: var(--maroon);
  text-transform: uppercase;
  text-align: right;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 1px;
  ${media.tablet`
    font-size: 22px;
  `}
`

export const HomeFooter = styled.div`
  height: 18vh;
  width: 100%;
  background-color: var(--maroon);
  color: var(--white);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  a {
    margin: auto;
    grid-column: 2;
    .arrow {
      background-color: transparent;
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
  .contact {
    display: none;
    a {
      line-height: 1.8;
      letter-spacing: 1.2px;
      transition: all 0.3s ease-out; 
      &:hover {
        color: var(--logo-red);
      }
    }
  }
  ${media.tablet`
    height: 18.5vh;
    width: 100%;
    background-color: var(--maroon);
    color: var(--white);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .arrow {
      background-color: transparent;
      border: none;
      margin: auto;
      grid-column: 2;
      &:focus {
        outline: none;
      }
    }
    .contact {
      display: block;
      text-align: right;
      grid-column: 3;
      padding: 45px 70px 0;
    }
  `}
`

export const ArrowIcon = styled.img `
  width: 20px;
  ${media.tablet`
    width: 30px;
  `}
`

export const FooterText = styled.a`
  color: white;
  text-decoration: none;
  margin: 20px 0;
  letter-spacing: 1.2px;
`

export const ContactText = styled.p`
  text-align: center;
  font-family: 'gourmet-hearth';
  margin: 0;
  padding: 5vh 0 0;
  color: var(--maroon);
  font-size: 30px;
`

const nameWiggle = keyframes`
  0% {
    margin-left: -10px
  }
  25% {
    margin-left: 0;
  }
  50% {
    margin-left: -10px
  }
  100% {
    margin-left: 0;
  }
`

const emailWiggle = keyframes`
  0% {
    margin-left: 2px
  }
  25% {
    margin-left: 12px;
  }
  50% {
    margin-left: 2px
  }
  100% {
    margin-left: 12px;
  }
`

export const ContactForm = styled.form`
  display: block;
  width: 84vw;
  padding: 5vh 8vw;
  color: var(--maroon);
  &.active {
    animation: ${nameWiggle} .8s ease-out forwards;
  }
  .recaptcha {
    display: none;
  }
  .details {
    margin-top: -15%;
  }
  p.input-container {
    width: 100%; 
    position: relative;
    top: 35px;
    margin-bottom: 35px;
  }

  label {
    color: var(--maroon);
    font-family: 'gourmet-hearth';
    position: absolute; 
    cursor: text;
    transform: translateY(-42px);
    margin-left: 10px;
    transition: transform 0.3s ease;
    left: 0;
    bottom: 74%;
  }

  textarea {
    color: var(--maroon);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    width: 96%;
    height: 30vh;
    font-size: 16px;
    border: none;
    border-radius: 0;
    padding: 5px 5px 5px 10px;
    margin: -10px 0 15px;
    font-family: 'averta-light';
    &:focus {
      outline: none;
      border-bottom: 1px solid #28a2a2;
    }
    &::placeholder {
      font-family: 'gourmet-hearth';
    }
  }
  .animation label {
    transform: translateY(-73px);
  }

  .details {
    display: flex;
    justify-content: space-between;
    width: 84vw;
    p.input-container {
      width: 100%; 
      position: relative;
      top: 50px;
      margin-bottom: 25px;
    }

    label {
      color: var(--maroon);
      font-family: 'gourmet-hearth';
      position: absolute; 
      cursor: text;
      transform: translateY(-42px);
      margin-left: 10px;
      transition: transform 0.3s ease;
      left: 0;
      bottom: -15px;
      &.email {
        margin-left: 20px;
      }
    }

    input {
      color: var(--maroon);
      width: 85%;
      height: 30px;
      border: none;
      padding: 5px 5px 5px 10px;
      margin: -10px 0 15px;
      border-radius: 0;
      font-size: 16px;
      font-family: 'averta-light';
      &::placeholder {
        font-family: 'gourmet-hearth';
      }
      &:focus {
        outline: none;
        border-bottom: 1px solid #28a2a2;
      }
      &.email {
        margin-left: 12px;
      }
      &.nameactive {
        animation: ${nameWiggle} 0.5s ease-in;
      }
      &.emailactive {
        animation: ${emailWiggle} 0.5s ease-in;
      }
    }
    .animation label {
      transform: translateY(-72px);
    }
  }
  ${media.tablet`
    width: 40vw;
    padding: 5vh 0vw;
    textarea {
      width: 97.5%;
      height: 40vh;
      margin-top: 1vh;
    }
    label {
      bottom: 35vh;
    }
    .animation label {
      transform: translateY(-9.25vh);
    }
    .details {
      width: 40vw;
      margin-top: -60px;
      input {
        width: 90%;
        height: 35px;
      }
      .animation label {
        transform: translateY(-80px);
      }
    }
    span {
      margin-top: 5px;
      font-size: 14px;
      &.name {
        margin-left: 13%;
      }
      &.email {
        margin-left: 34%;
      }
      &.message {
        margin-top: -4%;
        margin-left: 34%;
      }
    }
  `}
`

export const FormButton = styled.button`
  width: 75px;
  height: 40px;
  float: right;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  letter-spacing: 1.5px;
  font-family: 'gourmet-hearth';
  color: var(--bright-red);
  background-color: var(--maroon);
  cursor: pointer;
  opacity: 1;
  &:focus {
    outline: none;
    border: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  transition: all 0.5s ease-out; 
  &:hover {
    background-color: var(--light-grey);
  }
`

export const SubmitText = styled.p`
  color: var(--maroon);
  font-family: 'gourmet-hearth';
  font-size: 16px;
  margin-top: -5%;
  margin-left: 10vw;
  &.error {
    margin-left: 0;
    font-size: 14px;
    margin-top: -7%;
    margin-bottom: 0;
  }

  ${media.tablet`
    margin-left: 0;
    &.error {
      margin-left: 0;
      font-size: 16px;
      margin-top: -5%;
      margin-bottom: 0;
    }
  `}
`