import React from "react"
import ContactPage from "../components/ContactPage"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactPage />
  </Layout>
)

export default IndexPage
