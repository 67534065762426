import React from "react"
import styled from 'styled-components'
import Contact from "../ContactPage/contact"
import {Helmet} from 'react-helmet'

import SocialImg from '../../images/48h_social_sharing.png'

const PageCont = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: var(--bright-red);
  padding-bottom: 2em;
`

const ContactPage = () => {

  return (
    <PageCont>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="48H"/>
        <meta property="og:description" content="Social media crisis consultancy."/>
        <meta property="og:image" content={SocialImg}/>
      </Helmet>
      <Contact />
    </PageCont>
  )
}

export default ContactPage
