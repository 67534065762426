import React from 'react'
import styled, { keyframes } from 'styled-components'
import media from '../../styles/media'

const showTimeline = keyframes`
  0% {
    fill: #DF2127;
  }
  50% {
    fill: var(--maroon);
  }
  100% {
    fill: #DF2127;
  }
`
const AnimationContainer = styled.div`
  width : 85vw; 
  display: none;

  ${media.tablet`
    display: block;
  `}
`

const TimelineSvg = styled.svg`
  width: 85vw;
  fill: #DF2127;
  g {
    width: 85vw;
  }
  .st0{
    animation: ${showTimeline} 2s forwards infinite;
    &.two {
      animation-delay: 0.025s;
    }
    &.three {
      animation-delay: 0.05s;
    }
    &.four {
      animation-delay: 0.075s;
    }
    &.five {
      animation-delay: 0.1s;
    }
    &.six {
      animation-delay: 0.125s;
    }
    &.seven {
      animation-delay: 0.15s;
    }
    &.eight {
      animation-delay: 0.175s;
    }
    &.nine {
      animation-delay: 0.2s;
    }
    &.ten {
      animation-delay: 0.225s;
    }
    &.eleven {
      animation-delay: 0.25s;
    }
    &.twelve {
      animation-delay: 0.275s;
    }
    &.thirteen {
      animation-delay: 0.3s;
    }
    &.fourteen {
      animation-delay: 0.325s;
    }
    &.fifteen {
      animation-delay: 0.35s;
    }
    &.sixteen {
      animation-delay: 0.375s;
    }
    &.seventeen {
      animation-delay: 0.4s;
    }
    &.eighteen {
      animation-delay: 0.425s;
    }
    &.nineteen {
      animation-delay: 0.45s;
    }
    &.twenty {
      animation-delay: 0.475s;
    }
    &.twentyone {
      animation-delay: 0.5s;
    }
    &.twentytwo {
      animation-delay: 0.525s;
    }
    &.twentythree {
      animation-delay: 0.55s;
    }
    &.twentyfour {
      animation-delay: 0.575s;
    }
    &.twentyfive {
      animation-delay: 0.6s;
    }
    &.twentysix {
      animation-delay: 0.625s;
    }
    &.twentyseven {
      animation-delay: 0.65s;
    }
    &.twentyeight {
      animation-delay: 0.675s;
    }
    &.twentynine {
      animation-delay: 0.7s;
    }
    &.thirty {
      animation-delay: 0.725s;
    }
    &.thirtyone {
      animation-delay: 0.75s;
    }
    &.thirtytwo {
      animation-delay: 0.775s;
    }
    &.thirtythree {
      animation-delay: 0.8s;
    }
    &.thirtyfour {
      animation-delay: 0.825s;
    }
    &.thirtyfive {
      animation-delay: 0.85s;
    }
    &.thirtysix {
      animation-delay: 0.875s;
    }
    &.thirtyseven {
      animation-delay: 0.9s;
    }
    &.thirtyeight {
      animation-delay: 0.925s;
    }
    &.thirtynine {
      animation-delay: 0.95s;
    }
    &.fourty {
      animation-delay: 0.975s;
    }
    &.fourtyone {
      animation-delay: 1s;
    }
    &.fourtytwo {
      animation-delay: 1.025s;
    }
    &.fourtythree {
      animation-delay: 1.05s;
    }
    &.fourtyfour {
      animation-delay: 1.075s;
    }
    &.fourtyfive {
      animation-delay: 1.1s;
    }
    &.fourtysix {
      animation-delay: 1.125s;
    }
    &.fourtyseven {
      animation-delay: 1.15s;
    }
    &.fourtyeight {
      animation-delay: 1.175s;
    }
    &.fourtynine {
      animation-delay: 1.2s;
    }
    &.fifty {
      animation-delay: 1.225s;
    }
    &.fiftyone {
      animation-delay: 1.25s;
    }
    &.fiftytwo {
      animation-delay: 1.275s;
    }
    &.fiftythree {
      animation-delay: 1.3s;
    }
    &.fiftyfour {
      animation-delay: 1.325s;
    }
    &.fiftyfive {
      animation-delay: 1.35s;
    }
    &.fiftysix {
      animation-delay: 1.375s;
    }
    &.fiftyseven {
      animation-delay: 1.4s;
    }
    &.fiftyeight {
      animation-delay: 1.425s;
    }
    &.fiftynine {
      animation-delay: 1.45s;
    }
    &.sixty {
      animation-delay: 1.475s;
    }
    &.sixtyone {
      animation-delay: 1.5s;
    }
    &.sixtytwo {
      animation-delay: 1.525s;
    }
    &.sixtythree {
      animation-delay: 1.55s;
    }
    &.sixtyfour {
      animation-delay: 1.575s;
    }
    &.sixtyfive {
      animation-delay: 1.6s;
    }
    &.sixtysix {
      animation-delay: 1.625s;
    }
    &.sixtyseven {
      animation-delay: 1.65s;
    }
    &.sixtyeight {
      animation-delay: 1.675s;
    }
    &.sixtynine {
      animation-delay: 1.7s;
    }
    &.seventy {
      animation-delay: 1.725s;
    }
    &.seventyone {
      animation-delay: 1.75s;
    }
    &.seventytwo {
      animation-delay: 1.775s;
    }
  }
`

const TimelineDesk = () => (
  <AnimationContainer>
    <TimelineSvg viewBox="0 0 827.7 26.1" style={{enableBackground: 'new 0 0 827.7 26.1'}} className="desktop">
      <g>
        <g>
          <g>
            <path className="st0 nine" d="M100.6,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C100.5,15.6,100.6,16.1,100.6,16.7"/>
            <path className="st0 ten" d="M112.7,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C112.7,15.6,112.7,16.1,112.7,16.7"/>
            <path className="st0 twelve" d="M135.5,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C135.4,15.6,135.5,16.1,135.5,16.7"/>
            <path className="st0 twentyfive" d="M284.4,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C284.4,15.6,284.4,16.1,284.4,16.7"/>
            <path className="st0 eighteen" d="M204.5,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C204.5,15.6,204.6,16.1,204.5,16.7"/>
            <path className="st0 three" d="M27.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C31.3,24.4,29.9,26,27.2,26"
              />
            <path className="st0 four" d="M38.9,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C43,24.4,41.6,26,38.9,26"/>
            <path className="st0 five" d="M50.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C54.4,24.4,53,26,50.2,26"/>
            <path className="st0 two" d="M15.8,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C19.9,24.4,18.5,26,15.8,26"
              />
            <path className="st0 one" d="M4.1,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C8.3,24.4,6.9,26,4.1,26"/>
            <path className="st0 six" d="M62,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C66.1,24.4,64.7,26,62,26"/>
            <path className="st0 seven" d="M73.5,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C77.7,24.4,76.3,26,73.5,26"
              />
            <path className="st0 eleven" d="M120,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C124.1,24.4,122.7,26,120,26"
              />
            <path className="st0 eight" d="M84.9,26.1c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C89.1,24.4,87.7,26.1,84.9,26.1"/>
            <path className="st0 thirteen" d="M142.8,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C146.9,24.4,145.5,26,142.8,26"/>
            <path className="st0 fourteen" d="M154.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C158.3,24.4,156.9,26,154.2,26"/>
            <path className="st0 fifteen" d="M165.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C169.8,24.4,168.4,26,165.6,26"/>
            <path className="st0 sixteen" d="M177.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C181.7,24.4,180.3,26,177.6,26"/>
            <path className="st0 seventeen" d="M189,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C193.1,24.4,191.7,26,189,26"
              />
            <path className="st0 nineteen" d="M211.8,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C215.9,24.4,214.5,26,211.8,26"/>
            <path className="st0 twenty" d="M223.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C227.3,24.4,225.9,26,223.2,26"/>
            <path className="st0 twentyone" d="M234.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C238.7,24.4,237.3,26,234.6,26"/>
            <path className="st0 twentytwo" d="M246.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C250.8,24.4,249.4,26,246.6,26"/>
            <path className="st0 twentythree" d="M257.4,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C261.6,24.4,260.2,26,257.4,26"/>
            <path className="st0 twentyfour" d="M268.8,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C273,24.4,271.6,26,268.8,26
              "/>
            <path className="st0 twentysix" d="M291.7,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C295.8,24.4,294.4,26,291.7,26"/>
            <path className="st0 sixtyfour" d="M735.2,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C735.1,15.6,735.2,16.1,735.2,16.7"/>
            <path className="st0 sixtyseven" d="M770.1,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7C770,15.6,770.1,16.1,770.1,16.7"
              />
            <path className="st0 seventytwo" d="M827.7,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C827.7,15.6,827.8,16.1,827.7,16.7"/>
            <path className="st0 fiftyeight" d="M661.8,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C665.9,24.4,664.5,26,661.8,26"/>
            <path className="st0 fiftynine" d="M673.5,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C677.7,24.4,676.3,26,673.5,26"/>
            <path className="st0 sixty" d="M684.9,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C689,24.4,687.6,26,684.9,26
              "/>
            <path className="st0 sixtyone" d="M696.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C700.7,24.4,699.3,26,696.6,26"/>
            <path className="st0 sixtytwo" d="M708.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C712.3,24.4,710.9,26,708.2,26"/>
            <path className="st0 sixtysix" d="M754.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5s4.1,1.6,4.1,5v4.2C758.7,24.4,757.3,26,754.6,26"/>
            <path className="st0 sixtyfive" d="M742.7,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C746.8,24.4,745.4,26,742.7,26"/>
            <path className="st0 sixtythree" d="M719.5,26.1c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C723.7,24.4,722.3,26.1,719.5,26.1"/>
            <path className="st0 sixtyeight" d="M777.4,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C781.5,24.4,780.1,26,777.4,26"/>
            <path className="st0 sixtynine" d="M788.8,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C792.9,24.4,791.5,26,788.8,26"/>
            <path className="st0 seventy" d="M800.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C804.4,24.4,803,26,800.2,26
              "/>
            <path className="st0 seventyone" d="M812.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C816.3,24.4,814.9,26,812.2,26"/>
            <path className="st0 fiftyseven" d="M654.1,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C654.1,15.6,654.2,16.1,654.1,16.7"/>
            <path className="st0 fourtythree" d="M492.2,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C492.2,15.6,492.3,16.1,492.2,16.7"/>
            <path className="st0 twentyeight" d="M315,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C319.1,24.4,317.7,26,315,26"
              />
            <path className="st0 thirty" d="M338,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C342.2,24.4,340.8,26,338,26"
              />
            <path className="st0 thirtyone" d="M349.4,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C353.5,24.4,352.1,26,349.4,26"/>
            <path className="st0 twentynine" d="M326.4,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5s4.1,1.6,4.1,5v4.2C330.5,24.4,329.1,26,326.4,26"/>
            <path className="st0 twentyseven" d="M303.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5s4.1,1.6,4.1,5v4.2C307.7,24.4,306.3,26,303.6,26"/>
            <path className="st0 thirtyfive" d="M396.3,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C400.4,24.4,399,26,396.3,26
              "/>
            <path className="st0 thirtysix" d="M407.7,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5s4.1,1.6,4.1,5v4.2C411.8,24.4,410.4,26,407.7,26"/>
            <path className="st0 thirtyfour" d="M384.9,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C389,24.4,387.6,26,384.9,26
              "/>
            <path className="st0 thirtyseven" d="M419.1,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C423.2,24.4,421.8,26,419.1,26"/>
            <path className="st0 thirtyeight" d="M431,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C435.2,24.4,433.8,26,431,26"
              />
            <path className="st0 thirtynine" d="M442.5,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C446.6,24.4,445.2,26,442.5,26"/>
            <path className="st0 fourty" d="M453.9,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C458,24.4,456.6,26,453.9,26
              "/>
            <path className="st0 fourtyone" d="M465.3,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C469.4,24.4,468,26,465.3,26
              "/>
            <path className="st0 fourtytwo" d="M476.7,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C480.8,24.4,479.4,26,476.7,26"/>
            <path className="st0 fourtyfour" d="M500.1,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C504.2,24.4,502.8,26,500.1,26"/>
            <path className="st0 fourtyfive" d="M511.5,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C515.6,24.4,514.2,26,511.5,26"/>
            <path className="st0 fourtysix" d="M522.9,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C527,24.4,525.6,26,522.9,26
              "/>
            <path className="st0 fourtynine" d="M557,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C561.2,24.4,559.8,26,557,26"
              />
            <path className="st0 fiftyone" d="M580.4,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C584.5,24.4,583.1,26,580.4,26"/>
            <path className="st0 fiftythree" d="M607.9,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C607.9,15.6,607.9,16.1,607.9,16.7"/>
            <path className="st0 fiftyfive" d="M630.7,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7
              C630.7,15.6,630.7,16.1,630.7,16.7"/>
            <path className="st0 fiftysix" d="M638,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C642.1,24.4,640.7,26,638,26"
              />
            <path className="st0 fiftyfour" d="M615.1,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C619.3,24.4,617.9,26,615.1,26"/>
            <path className="st0 fiftytwo" d="M592.3,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C596.5,24.4,595.1,26,592.3,26"/>
            <path className="st0 fifty" d="M568.5,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C572.6,24.4,571.2,26,568.5,26"/>
            <path className="st0 fourtyeight" d="M545.6,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C549.7,24.4,548.3,26,545.6,26"/>
            <path className="st0 fourtyseven" d="M534.2,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2
              C538.3,24.4,536.9,26,534.2,26"/>
            <path className="st0 thirtytwo" d="M365,16.7c0,0.1,0,0.2,0,0.3v4.1c0,3.3-1.4,4.9-4.1,4.9c-2.7,0-4.1-1.6-4.1-4.9V17c0-0.3,0-0.6,0-0.9
              c0-0.5,0.1-1.1,0.3-1.6c0.2-0.6,0.6-1,1-1.5c0.6-0.5,1.1-0.9,1.7-1.4c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.4-0.6,0.4-1
              c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.3-0.4-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c0,0,0,0,0,0c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0,0,0
              c-0.4-0.5-0.7-1.3-0.8-2.1c0,0,0,0,0,0c-0.1-0.4-0.1-0.8-0.1-1.3V0h8.2c0,0,0.1,4.6,0.1,5c0,0.7-0.2,1.5-0.5,2.1
              c-0.2,0.4-0.5,0.7-0.8,1c-0.5,0.5-1.1,0.9-1.7,1.3c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.2,0,0.3,0,0.5
              c0,0.3,0.2,0.5,0.4,0.6c0.4,0.3,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1,0.8c0.5,0.5,0.8,1.1,0.9,1.7C365,15.6,365,16.1,365,16.7"/>
            <path className="st0 thirtythree" d="M372.8,26c-2.7,0-4.1-1.6-4.1-5v-4.2c0-3.3,1.4-5,4.1-5c2.7,0,4.1,1.6,4.1,5v4.2C377,24.4,375.6,26,372.8,26
              "/>
          </g>
        </g>
      </g>
    </TimelineSvg>
  </AnimationContainer>
)

export default TimelineDesk
