import React, { useState } from "react"
import { Formik } from 'formik'
import emailjs from 'emailjs-com'
import { ContactForm, FormButton, SubmitText } from "../../styles"
import ReCAPTCHA from 'react-google-recaptcha'
import { useEffect } from "react"
import $ from 'jquery'

const ContactUs = () => {
  const [showFormSuccess, setFormSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    $( document ).ready(function() {
      $(".name").each(function() { 
        if ($(this).val() !== "") {
          $(this).parent().addClass("animation");
        }
      });
      $(".email").each(function() { 
        if ($(this).val() !== "") {
          $(this).parent().addClass("animation");
        }
      });
      $(".message").each(function() { 
        if ($(this).val() !== "") {
          $(this).parent().addClass("animation");
        }
      });
    });
    
    //Add animation when input is focused
    $(".name").focus(function(){
      $(this).parent().addClass("animation animation-color");
      setShowError(false)
    });
    $(".email").focus(function(){
      $(this).parent().addClass("animation animation-color");
      setShowError(false)
    });
    $(".message").focus(function(){
      $(this).parent().addClass("animation animation-color");
    });
    
    //Remove animation(s) when input is no longer focused
    $(".name").focusout(function(){
      if($(this).val() === "")
        $(this).parent().removeClass("animation");
      $(this).parent().removeClass("animation-color");
    })
    $(".email").focusout(function(){
      if($(this).val() === "")
        $(this).parent().removeClass("animation");
      $(this).parent().removeClass("animation-color");
    })
    $(".message").focusout(function(){
      if($(this).val() === "")
        $(this).parent().removeClass("animation");
      $(this).parent().removeClass("animation-color");
    })
    
    var nameshake = document.getElementById('name-form');
    var emailshake = document.getElementById('email-form');

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if(document.getElementById('sendBtn')) {
      document.getElementById('sendBtn').addEventListener('click', function (e) {
          $(".name").parent().removeClass("animation");
          $(".name").parent().removeClass("animation-color");
          $(".email").parent().removeClass("animation");
          $(".email").parent().removeClass("animation-color");
          $(".message").parent().removeClass("animation");
          $(".message").parent().removeClass("animation-color");
        if (nameshake.value.length === 0 || !nameshake.value) {
          setShowError(true)
          nameshake.classList.add('nameactive');
          setTimeout(function(){
            nameshake.classList.remove('nameactive');
          }, 2000); 
        }
        if (emailshake.value.length === 0 || !emailshake.value || !regex.test(emailshake.value)) {
          setShowError(true)
          emailshake.classList.add('emailactive');
          setTimeout(function(){
            emailshake.classList.remove('emailactive');
          }, 2000); 
        }
      });
    }
  })

  return (
    <>
      <div>
        <Formik
          initialValues={{ name: "", email: "", message: "" }}
          validate={values => {
            let errors = {}
            if (!values.name) {
              errors.name = "Required"
            }
            if (!values.email) {
              errors.email = "Required"
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false)
            setFormSuccess(true)
            setTimeout(function(){
              setFormSuccess(false)
            }, 5000);
            resetForm({})
            emailjs.send(
              'service_frq4e19',
              'template_gw5z0me',
              {
                from_name: values.name,
                from_email: values.email,
                message: values.message,
              },
              'user_BANVUnROwc7ElTyUBLnJW'
            )
            resetForm({})
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <ContactForm onSubmit={handleSubmit} autoComplete="off">
              <div className="details">
                <p className="input-container">
                  <input 
                    id="name-form"
                    className="name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name} 
                  />
                  <label for="name" htmlFor="name" unselectable="on">Name</label>
                </p>
                <p className="input-container">
                  <input 
                    id="email-form"
                    className="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <label className="email" for="email" htmlFor="email" unselectable="on">Email</label>
                </p>
              </div>
              <p className="input-container">
                <textarea 
                  className="message"
                  type="text"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <label for="message" htmlFor="message" unselectable="on">Message</label>
                </p>
              {/* <ReCAPTCHA
                className="recaptcha"
                sitekey="6LeUpeEZAAAAAJGTgTNtX_tMGbQ1UTtRoPXnWfII"
              /> */}
              {!showFormSuccess && (
                <FormButton type="submit" disabled={isSubmitting} id="sendBtn">
                  send
                </FormButton>
              )}
              {showError && !isSubmitting && (
                <>
                <br/><br/>
                <SubmitText className="error">Complete required fields, time is critical.</SubmitText>
                </>
              )}
            </ContactForm>
          )}
        </Formik>
      </div>
      {showFormSuccess && 
        <SubmitText>Message sent, crisis acknowledged.</SubmitText>
      }
    </>
  )
}

export default ContactUs